function Welcome() {
    return (
        <div class="bg-gray-100">
            <div class="container mx-auto px-6 py-10">
                <div class="text-center">
                    <h1 class="my-4 font-bold leading-tight sm:text-4xl lg:text-5xl">Welcome to Lore Archives!</h1>
                    <p class="leading-normal lg:text-2xl sm:text-1xl mb-8">
                        A lore-based website made for history enjoyers alike.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
