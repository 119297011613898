function Maps() {
    return (
        <section class="bg-gray-100">
            <div class="container mx-auto px-6 py-5">
                <h2 class="text-4xl font-bold text-center text-gray-800 mb-8">
                    Maps
                </h2>
                <div class="flex flex-wrap sm:items-center">
                    <div class="w-full lg:w-1/3 px-2 mb-4">
                        <div class="bg-white rounded shadow py-2">
                            <img src="imgs/maps/map-eu.png" alt="Map of Europe" /> 
                        </div>
                    </div>
                    <div class="w-full lg:w-1/3 px-2 mb-4">
                        <div class="bg-white rounded shadow py-2">
                            <img src="imgs/maps/map-asia.png" alt="Map of Asia" /> 
                        </div>
                    </div>
                    <div class="w-full lg:w-1/3 px-2 mb-4">
                        <div class="bg-white rounded shadow py-2">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Maps;
