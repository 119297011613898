function Title() {
    return (
        <div class="bg-gray-200">
            <div class="container mx-auto p-8">
                <h1 class="my-4 text-center font-bold leading-tight sm:text-4xl lg:text-5xl">Maps</h1>
            </div>
        </div>
    );
}

export default Title;
