import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import reportWebVitals from './reportWebVitals';

import './index.css';
import Topbar from './components/Topbar';
import Footer  from "./components/Footer";

import Home from "./pages/Home";
import Lore from "./pages/Lore";
import Maps from "./pages/Maps";
import BattlePlans from "./pages/Battle-Plans";

ReactDOM.render(
    <Router>
        <Topbar />

        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/lore" element={<Lore />}></Route>
            <Route path="/maps" element={<Maps />}></Route>
            <Route path="/battle-plans" element={<BattlePlans />}></Route>
        </Routes>

        <Footer />
    </Router>,

    document.getElementById('root')
);

//reportWebVitals(console.log);
