function Overview() {
    return (
        <section class="bg-gray-200">
            <div class="container mx-auto px-6 py-5">
                <h2 class="text-4xl font-bold text-center text-gray-800 mt-4 mb-4">
                    Overview
                </h2>
                <div class="flex flex-wrap">
                    <p class="p-6 sm:w-full lg:w-1/3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor commodo ullamcorper a lacus vestibulum sed. Sed faucibus turpis in eu.
                    </p>
                    <p class="p-6 sm:w-full lg:w-1/3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor commodo ullamcorper a lacus vestibulum sed. Sed faucibus turpis in eu.
                    </p>
                    <p class="p-6 sm:w-full lg:w-1/3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor commodo ullamcorper a lacus vestibulum sed. Sed faucibus turpis in eu.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Overview;
