import Title from "../components/Maps/Title";
import Content from "../components/Maps/Content";

function Maps() {
    return (
        <div>
            <Title />

            <div class="bg-gray-100">
                <div class="container mx-auto p-4">
                    <div class="flex flex-wrap">
                        <Content src="imgs/maps/map-eu.png" />
                        <Content src="imgs/maps/map-asia.png" />
                        <Content src="" />
                        <Content src="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Maps;