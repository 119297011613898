import Welcome from '../components/Home/Welcome';
import Overview from '../components/Home/Overview';
import Maps from '../components/Home/Maps';
import Team from '../components/Home/Team';

function Home() {
    return (
        <div>
            <Welcome />
            <Overview />
            <Maps />
            <Team />
        </div>
    );
}

export default Home;
