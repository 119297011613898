function Team() {
    return (
        <section class="bg-gray-200">
            <div class="container mx-auto px-6 py-10">
                <h2 class="text-4xl font-bold text-center text-gray-800 mb-8">
                    Meet Our Team!
                </h2>
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-1/3 px-2 mb-4">
                        <div class="bg-white rounded shadow py-2">
                            <img class="p-4 float-left w-28 h-28" src="imgs/pfp.png" alt="profile" />
                            <p class="text-gray-800 text-base px-6 mb-5">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor commodo ullamcorper a lacus vestibulum sed. Sed faucibus turpis in eu.
                            </p>
                            <p class="text-gray-500 text-xs md:text-sm px-6">BoomDev</p>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/3 px-2 mb-4">
                        <div class="bg-white rounded shadow py-2">
                            <img class="p-4 float-left w-28 h-28" src="imgs/pfp.png" alt="profile" />
                            <p class="text-gray-800 text-base px-6 mb-5">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor commodo ullamcorper a lacus vestibulum sed. Sed faucibus turpis in eu.
                            </p>
                            <p class="text-gray-500 text-xs md:text-sm px-6">Imagine0824</p>
                        </div>
                    </div>
                    <div class="w-full lg:w-1/3 px-2 mb-4">
                        <div class="bg-white rounded shadow py-2">
                            <img class="p-4 float-left w-28 h-28" src="imgs/pfp.png" alt="profile" />
                            <p class="text-gray-800 text-base px-6 mb-5">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor commodo ullamcorper a lacus vestibulum sed. Sed faucibus turpis in eu.
                            </p>
                            <p class="text-gray-500 text-xs md:text-sm px-6">Sun Yat-Sans</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Team;
